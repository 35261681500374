import { Link } from "react-router-dom";
import component from "../../constants/urls";
import { 
  Card,
  CardContent,
  CardHeader,
  CardTitle
 } from "../ui/card";
import { Input } from "../ui/input";
import { Label } from "../ui/label";
import { Button } from "../ui/button";

export default function ForgetPasswordConatiner() {
  return (
    <div className="flex flex-col">
      <Card className="min-h-[40vh] lg:w-[35vw] lg:px-10 lg:py-10">
        <CardHeader>
          <CardTitle className="text-2xl text-foreground">Reset your password</CardTitle>
          <p className="text-sm">
            Forgotten your password? Enter your email address below, and we'll email instructions for setting a new one.
          </p>
        </CardHeader>
        <CardContent className="flex flex-col gap-6 lg:gap-9">
          <form className="flex flex-col gap-3 lg:gap-5">
            <div className="flex flex-col gap-3">
              <Label htmlFor="email" className="label">
                Email address
              </Label>
              <Input
                type="text"
                name="email"
                id="email"
                placeholder="Enter e-mail"
                className="input_style"
              />
            </div>
            <Button className="w-full py-5">Reset password</Button>
          </form>
          <div className="flex justify-between">
            <p className="text-sm text-primary font-medium hover:text-primary/80 transition-all">
              <Link to={component.login.url}>Login</Link>
            </p>
            <p className="text-sm text-primary font-medium hover:text-primary/80 transition-all">
              <Link to={component.signup.url}>Sign Up</Link>
            </p>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
