import { atomWithStorage } from "jotai/utils";

export const DashboardViews = [1, 2, 3] as const;

const initialState: CommonStateType = {
  sidebarExpand: false,
  isLoading: false,
  modalIsOpen: false,
  currentScanner: "",
  dashboardView: 1,
};

interface CommonStateType {
  sidebarExpand: boolean;
  isLoading: boolean;
  modalIsOpen: boolean;
  currentScanner: string;
  dashboardView: (typeof DashboardViews)[number];
}

export const commonAtom = atomWithStorage<CommonStateType>(
  "commonAtom",
  initialState
);
