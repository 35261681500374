import { clsx, type ClassValue } from "clsx"
import { twMerge } from "tailwind-merge"
 
export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export const getAuthHeader = () => {
  const token = localStorage.getItem("token");
  const header = {
    // "Content-Type": "application/json",
    headers: { Authorization: `Bearer ${token}` },
  };
  return header;
};
